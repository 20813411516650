'use client';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Button,
} from '@app/ui-system';
import { UserCircle, Moon, SunDim } from '@phosphor-icons/react';
import NextLink from 'next/link';
import { useRouter } from 'next/navigation';
import { useContext } from 'react';

import {
  getBrowserAuthClient,
  useSession,
} from '@/services/auth/getBrowserAuthClient';

import { LogoIcon } from './LogoIcon';
import { DarkModeContext } from '../dark-mode-provider/DarkModeProvider';

export const Navigation = () => {
  const router = useRouter();
  const { darkMode, setMode } = useContext(DarkModeContext);
  const client = getBrowserAuthClient();

  const { user, isLoading } = useSession();

  return (
    <div className="border-b border-solid border-zinc-100 dark:border-zinc-800">
      <div className="container mx-auto p-4">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-start-1 col-end-6">
            <div className="flex items-center gap-2 md:gap-4">
              <NextLink
                className="flex items-center gap-2 text-lg font-bold lg:mr-4"
                href="/"
              >
                <LogoIcon />
                Brumly
              </NextLink>

              <NextLink href="/oferta/dodaj">
                <Button size="xs" variant="secondary">
                  Dodaj
                </Button>
              </NextLink>

              <NextLink href="/oferty">Oferty</NextLink>
            </div>
          </div>

          <div className="col-span-4 col-end-13">
            <div className="flex justify-end gap-2">
              <div className="flex h-8 items-center space-x-4">
                {!isLoading && !user && (
                  <NextLink href="/auth/login">Zaloguj</NextLink>
                )}

                {user && (
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      {!user.avatar && <UserCircle size={22} />}
                      {user.avatar && (
                        <img
                          alt="avatar"
                          referrerPolicy="no-referrer"
                          src={user.avatar}
                          className="h-8 w-8 max-w-fit rounded-full bg-gray-900"
                        />
                      )}
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-56">
                      <NextLink href="/profil">
                        <DropdownMenuCheckboxItem>
                          Profil
                        </DropdownMenuCheckboxItem>
                      </NextLink>
                      <NextLink href="/oferta/dodaj">
                        <DropdownMenuCheckboxItem>
                          Dodaj ogłosznie
                        </DropdownMenuCheckboxItem>
                      </NextLink>
                      <NextLink href="/profil/oferty">
                        <DropdownMenuCheckboxItem>
                          Moje oferty
                        </DropdownMenuCheckboxItem>
                      </NextLink>
                      <NextLink href="/profil/ulubione">
                        <DropdownMenuCheckboxItem>
                          Ulubione
                        </DropdownMenuCheckboxItem>
                      </NextLink>
                      <DropdownMenuCheckboxItem
                        onClick={async () => {
                          await client.auth.signOut();
                          router.push('/');
                        }}
                      >
                        Wyloguj
                      </DropdownMenuCheckboxItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}

                <button
                  type="button"
                  onClick={() => {
                    const newMode = darkMode === 'dark' ? 'light' : 'dark';
                    setMode(newMode);
                    window.document.documentElement.classList.toggle('dark');
                  }}
                  className="mr-2"
                >
                  {darkMode === 'dark' ? (
                    <Moon size={22} />
                  ) : (
                    <SunDim size={22} />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
