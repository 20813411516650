'use client';

import { Button } from '@app/ui-system';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ContainerSmall } from '../layout/ContainerSmall';

export const CookieConsent = () => {
  const [isCookieConsent, setIsCookieConsent] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    setIsCookieConsent(cookieConsent !== 'true');
  }, []);

  return (
    <div
      className={twMerge(
        !isCookieConsent && 'hidden',
        'fixed bottom-0 z-10 w-full bg-zinc-800 p-6',
      )}
    >
      <ContainerSmall>
        <div className="flex flex-col md:flex-row">
          <p className="mr-8 text-sm text-zinc-200">
            Korzystamy z plików cookies w celu zapewnienia poprawnego
            funkcjonowania serwisu, podniesienia jakości świadczonych usług oraz
            w celach marketingowych. Dodatkowe informacje znajdziesz w naszej
            Polityce Prywatności. Warunki przechowywania lub dostępu do plików
            cookies możesz dostosować w ustawieniach Twojej przeglądarki.
            <Link href="/privacy-policy">
              Polityce dotyczącej plików cookie
            </Link>
          </p>
          <Button
            className="mt-4"
            onClick={() => {
              localStorage.setItem('cookieConsent', 'true');
              setIsCookieConsent(false);
            }}
          >
            Akceptuj
          </Button>
        </div>
      </ContainerSmall>
    </div>
  );
};
