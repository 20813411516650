export const SUPABASE_LOCALIZATION = {
  variables: {
    forgotten_password: {
      button_label: 'Resetuj hasło',
      password_label: 'Hasło',
      email_input_placeholder: 'Twój adres email',
      loading_button_label: 'Ładowanie',
      email_label: 'Adres email',
      link_text: 'Zaponiałeś hasła?',
      confirmation_text: 'Sprawdź swoją skrzynkę email',
    },
    sign_up: {
      button_label: 'Zarejestruj',
      email_label: 'Adres email',
      password_input_placeholder: 'Twoje hasło',
      password_label: 'Hasło',
      email_input_placeholder: 'Twój adres email',
      link_text: 'Rejestracja',
      loading_button_label: 'Ładowanie',
      confirmation_text: 'Potwierdź swój adres email',
    },
    magic_link: {
      button_label: 'Wyślij magic link',
      email_input_label: 'Adres email',
      email_input_placeholder: 'Twój adres email',
      link_text: 'Magic link',
    },
    sign_in: {
      email_label: 'Adres email',
      password_label: 'Hasło',
      email_input_placeholder: 'Twój adres email',
      password_input_placeholder: 'Twoje hasło',
      button_label: 'Zaloguj',
      loading_button_label: 'Ładowanie',
      social_provider_text: 'Zaloguj przez',
      link_text: 'Masz już konto? Zaloguj',
    },
  },
};

export const LANG = 'pl-PL';

export const SUPABASE_TOKEN = 'supabase-auth-token';

export const ROUTES = {
  oferty: 'oferty',
  oferta: 'oferta',
  prywatne: 'firma',
} as const;

export const ICON_SIZE = {
  small: 16,
  medium: 20,
  big: 24,
  xl: 28,
} as const;

export const LOCAL_STORAGE_KEYS = {
  appOffersList: 'app-offers-list',
} as const;

export enum OffersMode {
  grid,
  list,
}
