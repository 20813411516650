import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/apps/frontend/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ArrowGoUp"] */ "/vercel/path0/apps/frontend/src/modules/arrow-up/ArrowUp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieConsent"] */ "/vercel/path0/apps/frontend/src/modules/cookie-consent/CookieConsent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navigation"] */ "/vercel/path0/apps/frontend/src/modules/navigation/Navigation.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/frontend/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.23.0_@opentelemetry+api@1.8.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.23.0_@opentelemetry+api@1.8.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-loading-skeleton@3.4.0_react@18.2.0/node_modules/react-loading-skeleton/dist/skeleton.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/pure-react-carousel@1.30.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/pure-react-carousel/dist/react-carousel.es.css");
