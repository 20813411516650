import { CircleNotch } from '@phosphor-icons/react';
import { type VariantProps, cva } from 'class-variance-authority';

import { cn } from '../../lib/utils';

type LoaderOverlayProps = {
  isLoading: boolean;
};

export const LoaderOverlay = ({ isLoading }: LoaderOverlayProps) => {
  if (!isLoading) return null;

  return (
    <div className="absolute z-50 h-full w-full rounded-lg bg-opacity-75">
      <div className="flex h-full items-center justify-center">
        <Spinner size="md" isLoading />
      </div>
    </div>
  );
};

const spinnerVariants = cva('', {
  variants: {
    size: {
      sm: 'h-5 w-5',
      md: 'h-8 w-8',
      lg: 'h-12 w-12',
    },
    color: {
      dark: 'text-zinc-900',
      gray: 'text-slate-500',
      light: 'text-slate-100',
    },
  },
  defaultVariants: {
    size: 'md',
    color: 'gray',
  },
});

type SpinnerProps = {
  isLoading: boolean;
} & VariantProps<typeof spinnerVariants>;

export const Spinner = ({ isLoading, ...props }: SpinnerProps) => {
  return isLoading ? (
    <div className={cn('animate-spin-slow')}>
      <CircleNotch weight="bold" className={cn(spinnerVariants(props))} />
    </div>
  ) : null;
};
