import { CookieOptions, createBrowserClient, isBrowser } from '@supabase/ssr';
import { parse, serialize } from 'cookie';
import { useEffect, useMemo, useState } from 'react';

import { ENV } from '@/services/config/config';

import { UserResponse } from './auth.types';
import { parseSessionUser } from './parseSessionUser';

const DEFAULT_OPTS: CookieOptions = {
  secure: true,
  domain: ENV.DOMAIN,
};

export const getBrowserAuthClient = () => {
  return createBrowserClient(ENV.SUPABASE_URL, ENV.SUPABASE_ANON_KEY, {
    cookies: {
      get(key) {
        if (isBrowser()) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return parse(document.cookie)[key];
        }
        return null;
      },
      set(key, value, options) {
        if (isBrowser()) {
          document.cookie = serialize(key, value, {
            ...options,
            ...DEFAULT_OPTS,
          });
        }
      },
      remove(key, options) {
        if (isBrowser()) {
          document.cookie = serialize(key, '', {
            ...options,
            ...DEFAULT_OPTS,
          });
        }
      },
    },
  });
};

export const useSession = () => {
  const client = getBrowserAuthClient();
  const [user, setUser] = useState<UserResponse | undefined>(undefined);

  client.auth.onAuthStateChange((event) => {
    if (event === 'SIGNED_OUT') {
      setUser(null);
    }
  });

  useEffect(() => {
    const getSession = async () => {
      const { data: session } = await client.auth.getSession();
      if (!session.session) {
        setUser(null);
        return;
      }

      const { data } = await client.auth.getUser();
      if (data.user) {
        setUser(data.user);
      }
      if (data === null) {
        setUser(null);
      }
    };
    getSession();
  }, [client]);

  return {
    user: useMemo(() => parseSessionUser(user), [user]),
    isLoading: user === undefined,
  };
};
