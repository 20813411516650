import { z } from 'zod';

const envSchema = z.object({
  GRAPHQL_ENDPOINT: z.string(),
  HOST: z.string(),
  DOMAIN: z.string(),
  API_ENDPOINT: z.string(),
  SUPABASE_URL: z.string(),
  SUPABASE_ANON_KEY: z.string(),
  NODE_ENV: z.string(),
  SERVICE: z.enum(['SERVICE_PRODUCTION']).optional(),
});

const envs = envSchema.safeParse({
  GRAPHQL_ENDPOINT: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
  HOST: process.env.NEXT_PUBLIC_HOST,
  DOMAIN: process.env.NEXT_PUBLIC_DOMAIN,
  API_ENDPOINT: process.env.NEXT_PUBLIC_API_ENDPOINT,
  SUPABASE_URL: process.env.NEXT_PUBLIC_SUPABASE_URL,
  SUPABASE_ANON_KEY: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
  NODE_ENV: process.env.NODE_ENV,
  SERVICE: process.env.NEXT_PUBLIC_SERVICE,
});

if (!envs.success) {
  // eslint-disable-next-line no-console
  console.error(envs.error);
  throw new Error('!!! CONFIG ERROR: Invalid environment variables !!!');
}

export const ENV = envs.data;
