'use client';

import { CaretUp } from '@phosphor-icons/react';
import { twMerge } from 'tailwind-merge';

import { useScrollYPosition } from '@/hooks/useScrollYPosition';

export const ArrowGoUp = () => {
  const posY = useScrollYPosition();

  const onClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (posY < 400) return null;

  return (
    <button
      type="button"
      className={twMerge(
        'fixed bottom-1 right-1 z-20 lg:bottom-10 lg:right-10',
        'flex size-10 items-center justify-center rounded-full bg-zinc-300 shadow-md dark:bg-zinc-700',
      )}
      onClick={onClick}
    >
      <CaretUp weight="bold" size={18} />
    </button>
  );
};
