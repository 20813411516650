import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { Spinner } from '../atoms/LoadingOverlay/LoadingOverlay';
import { cn } from '../lib/utils';

const buttonVariants = cva(
  'inline-flex gap-1 relative min-w-[100px] items-center justify-center rounded-md text-sm font-semibold ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-zinc-950 dark:focus-visible:ring-zinc-800',
  {
    variants: {
      variant: {
        default:
          'bg-zinc-900 text-zinc-50 hover:bg-zinc-900/90 dark:bg-zinc-50 dark:text-zinc-900 dark:hover:bg-zinc-50/90',
        destructive:
          'bg-red-500 text-zinc-50 hover:bg-red-500/90 dark:bg-red-800 dark:text-red-50 dark:hover:bg-red-900/90',
        outline:
          'border-2 border-zinc-300 bg-transparent dark:bg-transparent hover:bg-zinc-100 hover:text-zinc-900 dark:border-zinc-500 dark:hover:bg-zinc-800 dark:hover:text-zinc-50',
        secondary: 'bg-blue-700 text-zinc-100 hover:bg-blue-600',
        ghost:
          'hover:bg-zinc-100 hover:text-zinc-900 bg-transparent dark:bg-transparent dark:hover:bg-zinc-800 dark:hover:text-zinc-50',
        link: 'text-zinc-900 dark:text-zinc-50',
        dark: 'dark:bg-zinc-900 dark:text-zinc-50 dark:hover:bg-zinc-800/90 dark:border-none bg-white border border-zinc-200 text-zinc-900 hover:bg-white/90',
      },
      size: {
        default: 'h-10 px-4 py-2',
        xs: 'h-8 px-2 min-w-fit',
        sm: 'h-9 px-3',
        lg: 'h-12 px-8 text-lg font-bold',
        icon: 'h-10 w-10',
      },
      width: {
        full: 'w-full',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

type ButtonProps = React.ButtonHTMLAttributes<
  HTMLButtonElement | HTMLAnchorElement
> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean;
    isLoading?: boolean;
    href?: string;
  };

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, width, children, isLoading, href, ...props },
    ref,
  ) => {
    if (href) {
      return (
        <a
          className={cn(buttonVariants({ variant, size, className, width }))}
          href={href}
          disabled={isLoading || props.disabled}
          {...props}
        >
          {children}
        </a>
      );
    }

    return (
      <button
        type="button"
        className={cn(buttonVariants({ variant, size, className, width }))}
        ref={ref}
        disabled={isLoading || props.disabled}
        {...props}
      >
        {!isLoading && children}
        <div className="absolute">
          <Spinner size="sm" color="light" isLoading={isLoading || false} />
        </div>
      </button>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
