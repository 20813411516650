import { useToast } from '@app/ui-system/src/components/use-toast';
import { isBrowser } from '@supabase/ssr';
import { devtoolsExchange } from '@urql/devtools';
import { fetchExchange, cacheExchange, Client, mapExchange } from 'urql/core';

import { ErrorStatus } from '@/generated/graphql';

import { ENV } from '../config/config';

const errorMessages: Record<ErrorStatus, string> = {
  UNAUTHORIZED: 'Niezautoryzowany dostęp',
  BAD_REQUEST: 'Coś poszło nie tak, spróbuj ponownie później',
  NOT_FOUND: 'Nie znaleziono zasobu',
};

export const urqlDevTool =
  ENV.NODE_ENV === 'development' ? [devtoolsExchange] : [];

export const getClient = (toastArg: ReturnType<typeof useToast>['toast']) => {
  return new Client({
    url: ENV.GRAPHQL_ENDPOINT,
    exchanges: [
      ...urqlDevTool,
      mapExchange({
        onError: (errors) => {
          errors?.graphQLErrors?.forEach((error) => {
            const code = error.extensions.code as ErrorStatus;
            toastArg({
              title: 'Błąd',
              description: errorMessages[code] || 'Nieznany błąd API',
              variant: 'destructive',
            });
          });
        },
      }),
      cacheExchange,
      fetchExchange,
    ],
    fetchOptions: {
      credentials: 'include',
      headers: {
        bearer: isBrowser() ? document?.cookie : '',
      },
    },
  });
};

export const urqlClient = getClient;
