export const LogoIcon = () => {
  return (
    <svg
      viewBox="0 0 350 350"
      width="28"
      height="28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x=".5"
        y=".5"
        width="349"
        height="349"
        rx="47.5"
        fill="#DB422D"
        stroke="#000"
      />
      <path
        d="M158.23 172.6c11.793 2.513 21.267 8.41 28.42 17.69 7.153 9.087 10.73 19.527 10.73 31.32 0 17.013-5.993 30.547-17.98 40.6-11.793 9.86-28.323 14.79-49.59 14.79H34.98V73.42h91.64c20.687 0 36.83 4.737 48.43 14.21 11.793 9.473 17.69 22.33 17.69 38.57 0 11.987-3.19 21.943-9.57 29.87-6.187 7.927-14.5 13.437-24.94 16.53Zm-73.66-16.82h32.48c8.12 0 14.307-1.74 18.56-5.22 4.447-3.673 6.67-8.99 6.67-15.95 0-6.96-2.223-12.277-6.67-15.95-4.253-3.673-10.44-5.51-18.56-5.51H84.57v42.63Zm36.54 81.2c8.313 0 14.693-1.837 19.14-5.51 4.64-3.867 6.96-9.377 6.96-16.53 0-7.153-2.417-12.76-7.25-16.82-4.64-4.06-11.117-6.09-19.43-6.09H84.57v44.95h36.54Zm154.622-94.83c5.8-8.893 13.05-15.853 21.75-20.88 8.7-5.22 18.367-7.83 29-7.83v52.49h-13.63c-12.373 0-21.653 2.707-27.84 8.12-6.187 5.22-9.28 14.5-9.28 27.84V277h-49.59V115.18h49.59v26.97Z"
        fill="#fff"
      />
    </svg>
  );
};
