import { ReactNode, createContext, useMemo, useState } from 'react';

type DarkMode = {
  darkMode?: string;
  setMode: (arg: string) => void;
};

export const DarkModeContext = createContext<DarkMode>({
  darkMode: 'dark',
  setMode: () => {},
});

export const DarkModeProvider = ({
  children,
  darkMode,
}: {
  darkMode?: string;
  children: ReactNode;
}) => {
  const [mode, setMode] = useState(darkMode);

  return (
    <DarkModeContext.Provider
      value={useMemo(
        () => ({
          darkMode: mode,
          setMode,
        }),
        [mode],
      )}
    >
      {children}
    </DarkModeContext.Provider>
  );
};
