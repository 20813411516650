import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { LOCAL_STORAGE_KEYS, OffersMode } from '@/services/consts';

type AppConfigProviderProps = {
  config: Record<string, string>;
  setConfig: (arg: Record<string, string>) => void;
};

export const AppConfigContext = createContext<AppConfigProviderProps>({
  config: {},
  setConfig: () => {},
});

export const AppConfigProvider = ({ children }: { children: ReactNode }) => {
  const [config, setConfig] = useState({});
  const [appOffersMode] = useLocalStorage(
    LOCAL_STORAGE_KEYS.appOffersList,
    OffersMode.grid,
  );

  useEffect(() => {
    setConfig({ appOffersMode });
  }, [appOffersMode]);

  return (
    <AppConfigContext.Provider
      value={useMemo(
        () => ({
          config,
          setConfig,
        }),
        [config],
      )}
    >
      {children}
    </AppConfigContext.Provider>
  );
};
