import { UserResponse } from './auth.types';

export type UserSession = {
  id: string;
  email: string;
  fullName: string;
  avatar?: string;
  phone?: string;
};

export const parseSessionUser = (
  user?: UserResponse,
): UserSession | null | undefined => {
  if (user) {
    return {
      id: user.id,
      email: user.user_metadata.email || user.email,
      fullName: user.user_metadata.fullName || '',
      avatar: user.user_metadata.picture || '',
    };
  }

  return user;
};
