import { PropsWithChildren, memo } from 'react';
import { twMerge } from 'tailwind-merge';

export const ContainerSmall = memo(
  ({ children, className }: PropsWithChildren & { className?: string }) => {
    return (
      <div className={twMerge('mx-auto w-full max-w-5xl', className)}>
        {children}
      </div>
    );
  },
);
